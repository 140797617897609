// create function component for onBoard page
import React, { useState, useEffect } from "react";
import { useAxios } from "../hooks/axios";
import { API } from "../config";
import adyenLogo from "../assets/DIGITAL-Adyen-green-RGB.png";
import { useNavigate } from "react-router-dom";

export const HospitalityActivityPage = () => {
  const { loading, error, request } = useAxios();
  const { loading: isDeleting, request: deleteRequest } = useAxios();

  const [activityLog, setActivityLog] = useState(
    [] as {
      action: "ONBOARDING";
      cardId: "clj8eb8n50016933fqtf6bto8";
      createdAt: "2023-06-23T09:54:39.232Z";
      id: "clj8eb8xs0029933fdv2b4qs9";
      message: "ONBOARDING - 045D9022636781";
      terminalId: "S1F2-000158222750246";
      updatedAt: "2023-06-23T09:54:39.233Z";
      userId: "clj8eb7w80006933f9t6pkcpg";
    }[]
  );
  const [nfcData, setNfcData] = useState<{
    name?: string;
    id: string;
    createdAt: string;
  }>();
  const [downloadPassUrl, setDownloadPassUrl] = useState("");

  const navigation = useNavigate();

  const getActivityLogs = async ({ id }: { id: string }) => {
    const data = await request({
      url: "/activity",
      method: "GET",
      params: {
        userId: id,
      },
    });

    if (data) {
      setActivityLog(data.ActivityLog);
      setNfcData({
        createdAt: data.createdAt,
        id: data.id,
        name: data.name,
      });

      const API_HOST = API.HOST;
      setDownloadPassUrl(`${API_HOST}/wallets/pass/download/${data?.id}`);
    }

    return data;
  };

  // get url parmas and set userId
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id") || "";

  useEffect(() => {
    if (id) {
      getActivityLogs({
        id,
      });
    }
  }, [id]);

  useEffect(() => {
    console.log(error);
    if (
      error &&
      (error as any).response.data.message === "NoMatchingEntityFound"
    ) {
      alert(
        "We could not find any matching user. Maybe your link is out of date?"
      );
    } else if (error) {
      alert("Something went wrong. Please try again later.");
    }
  }, [error]);

  return (
    <div
      style={{
        width: "100vw",
      }}
      className="flex-column justify-content-center align-items-center">
      <div
        style={{
          backgroundColor: "#00112c",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        className="w-100 text-light d-flex flex-column justify-content-center align-items-center">
        <div
          style={{
            maxWidth: "80%",
            textAlign: "center",
          }}>
          <img className="mb-4" src={adyenLogo} style={{ maxWidth: 150 }} />

          {Boolean(activityLog.length) && (
            <>
              {nfcData?.name && <h1>Hi {nfcData.name}!</h1>}
              <h3 style={{ color: "#08bf51", fontSize: "1.5em" }}>
                We've added these items to your account.
              </h3>
            </>
          )}

          {!id && !Boolean(activityLog.length) && (
            <>
              <h1>Demo</h1>
              {nfcData?.name && <h1>Hi {nfcData.name}!</h1>}
              {/* <h3 style={{ color: "#08bf51" }}>
                Check activity by entering a phone number
              </h3> */}
            </>
          )}
        </div>
      </div>
      <div className="mt-3 register-div w-100 d-flex flex-column align-items-center">
        {loading && (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {activityLog && (
          <div
            className="activity-table"
            style={{
              marginBottom: 100,
            }}>
            <div
              className="row"
              style={{
                borderBottom: "2px dashed #08bf51",
                fontWeight: "bold",
              }}>
              <div>Time</div>
              <div>Item</div>
              <div>Location</div>
              <div>Qty</div>
            </div>
            {[...activityLog]
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((o, ix, arr) => {
                const data = o.message.startsWith("JSON:")
                  ? JSON.parse(o.message.replace("JSON: ", ""))
                  : o.message;

                const isRegistration = o.action === "ONBOARDING";

                const amount = parseFloat(data?.amount);
                return (
                  <div className="row" key={o.id}>
                    <div>
                      {new Date(o?.createdAt || "").toLocaleTimeString(
                        "nl-NL",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </div>
                    <div>{isRegistration ? "-" : data.item}</div>
                    <div>
                      {data?.source || (isRegistration ? "Registration" : "-")}
                    </div>
                    <div>
                      {!Number.isNaN(amount) ? amount.toLocaleString() : "-"}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>

      <div className="text-center mb-3 mt-5">
        {id && (
          <div className="text-center mb-3" style={{ fontSize: "small" }}>
            We only save this data during the demo, we delete it afterwards
          </div>
        )}
        {id && (
          <button
            className="btn btn-adyen"
            disabled={isDeleting}
            onClick={() => {
              deleteRequest({
                url: `/hospitality/deleteUser/${id}`,
                method: "DELETE",
              }).then(() => {
                alert(
                  "Your data has been deleted. Thank you for participating in our event."
                );
                navigation("/activity");
                setTimeout(() => {
                  window.location.reload();
                }, 0);
              });
            }}>
            Delete your data now
          </button>
        )}
      </div>

      {/* {hasPin && <a href="/">POS Page</a>} */}
    </div>
  );
};
