import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { OnBoardPage } from "./pages/OnBoardPage";
import { OnBoardPageByPhone } from "./pages/OnBoardPageByPhone";
import { ActivityEmailPage } from "./pages/ActivityEmailPage";
import { ActivityPhonePage } from "./pages/ActivityPhonePage";
import { RegisterPage } from "./pages/RegisterPage";
import { HospitalityActivityPage } from "./pages/HospitalityActivityPage";
import { HospitalityPosPage } from "./pages/HospitalityPosPage";
import { UserOverviewPage } from "./pages/UserOverviewPage";
import { SetupTerminalPage } from "./pages/SetupTerminalPage";
import { SetupServiceLocationPage } from "./pages/SetupServiceLocationPage";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HospitalityPosPage />} />
        <Route path="/register/qrcode" element={<OnBoardPage />} />
        <Route path="/register/phone" element={<OnBoardPageByPhone />} />

        <Route path="/activity" element={<HospitalityActivityPage />} />
        {/* <Route path="/activity" element={<ActivityPage />} /> */}
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/activity-email" element={<ActivityEmailPage />} />
        <Route path="/activity-phone" element={<ActivityPhonePage />} />
        <Route path="/user-overview" element={<UserOverviewPage />} />
        <Route path="/setup-terminal" element={<SetupTerminalPage />} />
        <Route path="/setup-service-location" element={<SetupServiceLocationPage />} />
      </Routes>
    </BrowserRouter>
  );
};
