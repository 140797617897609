import React from "react";
import TerminalList from "../components/TerminalList";
import { useLayoutEffect, useState } from "react";
import { useAxios } from "../hooks/axios";
import adyenLogo from "../assets/DIGITAL-Adyen-green-RGB.png";

interface SetupPageProps {
  // Add any props you need for the SetupPage component
}

export const SetupTerminalPage: React.FC<SetupPageProps> = () => {
  const [pageLoading, setpageLoading] = useState(true);
  const [isPinWrong, setIsPinWrong] = useState(false);
  const { loading, error, request } = useAxios();

  const veriftySetupPage = async () => {
    let pin: string = "";
    if (!localStorage.getItem("setup-pin")) {
      pin = window.prompt("Please enter your pin") || "";
      localStorage.setItem("setup-pin", pin);
    }

    try {
      const veriftyPin = localStorage.getItem("setup-pin") || "";
      const response = await request({
        url: "/veriftySetupPage",
        method: "POST",
        data: {
          pin: veriftyPin,
        },
      });
      if (response) {
        setIsPinWrong(false);
      }
    } catch (error) {
      setIsPinWrong(true);
      localStorage.removeItem("setup-pin");
    }

    setpageLoading(false);
  };

  useLayoutEffect(() => {
    veriftySetupPage();
  }, []);

  // Add your component logic here

  return (
    <div
      style={{
        width: "100vw",
      }}
      className="flex-column justify-content-center align-items-center"
    >
      <div
        style={{
          backgroundColor: "#00112c",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        className="w-100 text-light d-flex flex-column justify-content-center align-items-center"
      >
        <div
          style={{
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          <img className="mb-4" src={adyenLogo} style={{ maxWidth: 150 }} />
          <h1>Setup Terminal</h1>
          <h3 style={{ color: "#08bf51" }}></h3>
        </div>
      </div>
      <div className="mt-3 register-div w-100 d-flex flex-column justify-content-center align-items-center">
        {pageLoading}
        {isPinWrong && !pageLoading && <div>401: Unauthorized</div>}
        {!isPinWrong && !pageLoading && <TerminalList />}
      </div>
    </div>
  );
};
