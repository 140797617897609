// create axios hook
import axios, { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { API } from "../config";

export const useAxios = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const axiosInstance = axios.create({
    baseURL: API.HOST,
    headers: {
      Authorization: API.KEY,
    },
  });

  const request = async (options: AxiosRequestConfig) => {
    let response;
    let responseData;

    try {
      setLoading(true);
      console.log("baseUrl", API.HOST);
      response = await axiosInstance(options);
      responseData = response.data;
    } catch (error: any) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }

    return responseData;
  };

  return { loading, error, request };
};
