import React, { useState, useEffect } from "react";
import { useAxios } from "../hooks/axios";

interface Terminal {
  id: string;
  name: string;
  isTestTerminal: boolean;
  onboardTerminal: boolean;
  apiKey: string;
}

const TerminalList: React.FC = () => {
  const [terminals, setTerminals] = useState([]);
  const { loading, error, request } = useAxios();
  const [showModal, setShowModal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState<Terminal | null>(
    null
  );
  const [form, setForm] = useState({
    id: "",
    name: "",
    apiKey: "",
    isTestTerminal: false,
  });
  const [displayedApiKey, setDisplayedApiKey] = useState("");

  useEffect(() => {
    if (form.apiKey.length === 0) {
      setDisplayedApiKey("");
    } else {
      setDisplayedApiKey(`**************${form.apiKey.slice(-4)}`);
    }
  }, [form.apiKey]);

  const openEditTerminalModal = (terminal: Terminal) => {
    setSelectedTerminal(terminal);
    setForm({
      id: terminal.id,
      name: terminal.name,
      apiKey: terminal.apiKey,
      isTestTerminal: terminal.isTestTerminal,
    });
    setDisplayedApiKey(`**************${terminal.apiKey.slice(-4)}`);

    setShowModal(true);
  };

  const openCreateTerminalModal = () => {
    setSelectedTerminal(null);
    setForm({
      id: "",
      name: "",
      apiKey: "",
      isTestTerminal: false,
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formateDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.toLocaleTimeString()} \n${dateObj.toLocaleDateString()}`;
  };

  const handleCreateTerminal = async () => {
    try {
      const response = await request({
        url: "/terminal/setupPageCreateTerminal",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          id: form.id,
          name: form.name,
          isTestTerminal: form.isTestTerminal,
          apiKey: form.apiKey,
        },
      });

      if (response) {
        handleCloseModal();
        await fetchTerminals();
      }
    } catch (error) {}
  };

  const handleEditTerminal = async () => {
    try {
      const response = await request({
        url: "/terminal/setupPageUpdateTerminal",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          id: form.id,
          name: form.name,
          isTestTerminal: form.isTestTerminal,
          apiKey: form.apiKey,
        },
      });

      if (response) {
        handleCloseModal();
        await fetchTerminals();
      }
    } catch (error) {}
  };

  const handleDeleteTerminal = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return false;
    }
    try {
      const response = await request({
        url: "/terminal/setupPageDeleteTerminal",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          id,
        },
      });

      if (response) {
        await fetchTerminals();
      }
    } catch (error) {}
  };

  const handleShowTerminal = (terminalId: string, onboardTerminal: boolean) => {
    return (
      <div className="form-check form-switch">
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          Show/Hidden
        </label>
        <input
          className="form-check-input form-success"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          checked={onboardTerminal}
          onChange={() => {
            handleToggleShowTerminal(terminalId, !onboardTerminal);
          }}
        />
      </div>
    );
  };

  const handleToggleShowTerminal = async (
    terminalId: string,
    onboardTerminal: boolean
  ) => {
    if (window.confirm("Are you sure you want to change this?")) {
      const response = await request({
        url: "/terminal/setupPageSetShowHiddenTerminal",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          terminalId,
          onboardTerminal,
        },
      });

      if (response) {
        await fetchTerminals();
      }
    } else {
      return false;
    }
  };

  // Fetch terminals data from backend API
  const fetchTerminals = async () => {
    try {
      const response = request({
        url: "/terminal/setupPageGetTerminalList",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
        },
      });

      const data = await response;
      setTerminals(data);
    } catch (error) {
      console.error("Error fetching terminals:", error);
    }
  };

  const handleCopy = (apiKey: string) => () => {
    navigator.clipboard.writeText(apiKey);
  };

  useEffect(() => {
    fetchTerminals();
  }, []);

  return (
    <div>
      <button className="btn btn-adyen mb-3" onClick={openCreateTerminalModal}>
        Add terminal
      </button>
      <table className="overview">
        <thead>
          <th>#</th>
          <th className="hide-on-mobile">Terminal ID</th>
          <th>Terminal name</th>
          <th>Actions</th>
          <th className="hide-on-mobile">Updated at</th>
        </thead>
        <tbody>
          {terminals.map((terminal: any, index: number) => (
            <tr key={terminal.id}>
              <td>{index + 1}</td>
              <td className="hide-on-mobile">{terminal.id}</td>
              <td>{terminal.name}</td>

              <td>
                {handleShowTerminal(terminal.id, terminal.onboardTerminal)}
                <button
                  className="btn btn-primary m-2"
                  onClick={() => openEditTerminalModal(terminal)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    handleDeleteTerminal(terminal.id);
                  }}
                >
                  Delete
                </button>
              </td>
              <td className="hide-on-mobile">
                <pre>{formateDate(terminal.updatedAt)}</pre>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div className="modal" style={{ display: "block" }} id="terminalModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedTerminal ? "Edit Terminal" : "Create Terminal"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="mb-3">
                    <label htmlFor="terminalId" className="form-label">
                      Terminal ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="terminalId"
                      value={form.id}
                      disabled={selectedTerminal ? true : false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          id: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="terminalName" className="form-label">
                      Terminal name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="terminalName"
                      value={form.name}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="terminalApiKey" className="form-label">
                      Terminal Api Key
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="terminalApiKey"
                      value={displayedApiKey}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setForm({
                          ...form,
                          apiKey: newValue,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="terminalIsTest"
                      checked={form?.isTestTerminal}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          isTestTerminal: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="terminalIsTest"
                    >
                      Is test terminal
                    </label>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                {selectedTerminal && (
                  <button
                    className="btn btn-success"
                    onClick={handleCopy(JSON.stringify(form))}
                  >
                    Copy Terminal Info
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={
                    selectedTerminal
                      ? () => handleEditTerminal()
                      : () => handleCreateTerminal()
                  }
                >
                  {selectedTerminal ? "Update" : "Create"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TerminalList;
