// create function component for onBoard page
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAxios } from "../hooks/axios";

export const RegisterPage = () => {
  const { loading, error, request } = useAxios();
  const [terminalId, setTerminalId] = useState("");
  const [cardAlias, setCardAlias] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const hanldeConnect = async () => {
    if (!cardAlias || !name || !email) {
      alert("Please enter all the details");
      return;
    }

    // check email is vaild by regex
    const emailRegex = new RegExp(
      "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email");
      return;
    }

    const response = await request({
      url: "/register",
      method: "POST",
      data: {
        terminalId,
        cardAlias,
        name,
        email,
        phone,
      },
    });

    if (response?.userId) {
      alert("Your card is now connected to your profile");
      window.location.href = "/activity?id=" + response?.userId;
    }
  };

  const checkCardAliasIsOnboarded = async () => {
    return await request({
      url: "/checkCardAliasIsOnboarded",
      method: "POST",
      data: {
        cardAlias,
        terminalId,
      },
    });
  };

  useEffect(() => {
    // get url parmas and set cardAlias
    const urlParams = new URLSearchParams(window.location.search);
    const cardAlias = urlParams.get("cardAlias") || "";
    const terminalId = urlParams.get("terminalId") || "";
    checkCardAliasIsOnboarded().then((response) => {
      if (response == true) {
        alert("Card is already onboarded");
        window.location.href = "/activity?cardAlias=" + cardAlias;
      } else {
        setCardAlias(cardAlias);
        setTerminalId(terminalId);
      }
    });
  }, []);

  useEffect(() => {}, [cardAlias]);

  return (
    // 100vh bootstrap class
    <div>
      <div
        style={{
          minHeight: "50vh",
          backgroundColor: "#00112c",
        }}
        className="w-100 text-light d-flex flex-column justify-content-center align-items-center">
        <div
          style={{
            maxWidth: "80%",
            textAlign: "center",
          }}>
          <img
            className="mb-4"
            src="https://loyyo.nl/images/hitec-logo.png?v2"
            style={{ maxWidth: 60 }}
          />
          <h1>Register your card</h1>
          <h3 style={{ color: "#08bf51" }}>{cardAlias}</h3>
        </div>
      </div>
      <div className="mt-5 w-100 d-flex flex-column justify-content-center align-items-center">
        <div
          style={{
            maxWidth: "80vw",
            width: "400px",
          }}>
          <div className="d-flex flex-column w-100">
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-name">
                Name
              </span>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
                className="form-control"
                aria-label="input name"
                aria-describedby="inputGroup-name"
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-email">
                Email
              </span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="form-control"
                aria-label="input email"
                aria-describedby="inputGroup-email"
              />
            </div>
            <button
              className="btn btn-adyen"
              onClick={hanldeConnect}
              disabled={loading}>
              {loading ? "Just a moment..." : "Connect"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
