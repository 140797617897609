// create function component for onBoard page
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAxios } from "../hooks/axios";

export const OnBoardPage = () => {
  const { loading, error, request } = useAxios();
  const { loading: terminalLoading, request: reguestTerminal } = useAxios();
  const [terminalOptions, setTerminalOptions] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [getTerminalId, setGetTerminalId] = useState("");

  const hanldeConnect = async () => {
    if (!selectedTerminal) {
      alert("Please select terminal");
      return;
    }

    const response = await reguestTerminal({
      url: "/acquisitionOnboardByQRCode",
      method: "POST",
      data: {
        terminalId: selectedTerminal,
      },
    });
  };

  const getTerminalOptions = async () => {
    const response = await request({
      url: "/terminal/getOnboardingTerminals",
      method: "GET",
    });

    return response;
  };

  useEffect(() => {
    getTerminalOptions().then((response) => {
      // check response is not null and is an array
      if (response && Array.isArray(response)) {
        setTerminalOptions(response);
      }

      // get url parmas and set terminalId
      const urlParams = new URLSearchParams(window.location.search);
      const terminalId = urlParams.get("terminalId") || "";
      if (terminalId) {
        setSelectedTerminal(terminalId);
        setGetTerminalId(terminalId);
      }
    });
  }, []);

  return (
    // 100vh bootstrap class
    <div
      style={{
        height: "100vh",
        width: "100vw",
      }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div
        style={{
          backgroundColor: "#00112c",
        }}
        className="h-100 w-100 text-light d-flex flex-column justify-content-center align-items-center"
      >
        <div
          style={{
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          <img
            className="mb-4"
            src="https://loyyo.nl/images/hitec-logo.png?v2"
            style={{ maxWidth: 60 }}
          />
          <h1>Register your card</h1>
          <h3 style={{ color: "#08bf51" }}>
            Learn how payment-linked data works
          </h3>
        </div>
      </div>
      <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
        {!loading || (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {!terminalLoading || (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Continue on the terminal</p>
          </div>
        )}
        {loading || (
          <div
            style={{
              maxWidth: "80vw",
              width: "400px",
            }}
          >
            {getTerminalId && (
              <div className="mb-4">
                <h5>Terminal ID: {getTerminalId}</h5>
              </div>
            )}
            {!getTerminalId && (
              <div className="mb-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setSelectedTerminal(e.target.value)}
                  defaultValue={selectedTerminal}
                >
                  <option value="">Select a terminal</option>
                  {terminalOptions.map((terminalOption) => {
                    return (
                      <option value={terminalOption.id} key={terminalOption.id}>
                        {terminalOption.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div
              style={{
                height: "60px",
              }}
              className="d-flex w-100"
            >
              <button
                className="btn btn-adyen w-100"
                onClick={hanldeConnect}
                disabled={loading}
              >
                Connect
              </button>
            </div>
            <div className="d-flex justify-content-center">
              <a href="/activity-email">or check activity</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
