// create function component for onBoard page
import React,{ useState,useEffect,useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useAxios } from "../hooks/axios";
import adyenLogo from "../assets/DIGITAL-Adyen-green-RGB.png";
import { AxiosError } from "axios";

const locations: {
  id: string;
  location: string;
  items?: {
    name: string;
    price: number;
  }[];
  checkIn?: boolean;
}[] = [
  // there are example locations but we get them from the database now
  // { id: "reception", location: "Check in", checkIn: true },
  // {
  //   location: "Bar",
  //   id: "bar",
  //   items: [{ name: "Drink", price: 1 }],
  // },
  // {
  //   location: "Giftshop",
  //   id: "giftshop",
  //   items: [{ name: "Gift Bag", price: 1 }],
  // },
  // {
  //   location: "Restaurant",
  //   id: "restaurant",
  //   items: [{ name: "Dinner", price: 1 }],
  // },
  // {
  //   location: "Spa",
  //   id: "spa",
  //   items: [{ name: "Spa", price: 1 }],
  // },
  // {
  //   location: "Payment",
  //   id: "Payment",
  //   items: [{ name: "Pay", price: 0.01 }],
  //  },

  // {
  //   location: "Restaurant",
  //   id: "dinner",
  //   items: [{ name: "Dinner",price: 15 }],
  // },
];

interface IPosPayRequest {
  terminalId: string;
  amount: number;
  item: string;
  location: string;
}

export const HospitalityPosPage = () => {
  const { loading, error, request } = useAxios();
  const { loading: terminalLoading, request: reguestTerminal } = useAxios();

  const [terminalOptions, setTerminalOptions] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [selectedLocation, setSelectedLocation] =
    useState<(typeof locations)[number]>();
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [getTerminalId, setGetTerminalId] = useState("");

  const [isPinWrong, setIsPinWrong] = useState(false);

  useLayoutEffect(() => {
    if (!localStorage.getItem("pin")) {
      const pin = window.prompt("Please enter your pin");

      if (pin && pin !== "null") {
        localStorage.setItem("pin", pin);
      }
    }
  }, []);

  useEffect(() => {
    const terminalId = localStorage.getItem("terminalId");
    if (terminalId && terminalOptions.length) {
      const terminal = terminalOptions.find(
        (terminal) => terminal.id === terminalId
      );

      if (terminal) {
        setSelectedTerminal(terminalId);
      }
    }
  }, [terminalOptions]);

  const handleConnect = async () => {
    if (!selectedTerminal) {
      alert("Please select terminal");
      return;
    }

    if (selectedLocation?.checkIn) {
      await reguestTerminal({
        url: "/acquisitionOnboardByQRCode",
        method: "POST",
        data: {
          pin: localStorage.getItem("pin"),
          terminalId: selectedTerminal,
        },
      });
    } else {
      await reguestTerminal({
        url: "/hospitality/add-item",
        method: "POST",
        data: {
          pin: localStorage.getItem("pin"),
          terminalId: selectedTerminal,
          amount: selectedLocation?.items?.[0].price || "",
          item: selectedLocation?.items?.[0].name || "",
          location: selectedLocation?.location,
        } as IPosPayRequest,
      });
    }
  };

  const getTerminalOptions = async () => {
    try {
      const response = await request({
        url: "/terminal/getOnboardingTerminals",
        method: "POST",
        data: {
          pin: localStorage.getItem("pin"),
        },
      });
      return response;
    } catch (ex) {
      if (
        ((ex as AxiosError).response?.data as { message: string })?.message ===
        "WrongPin"
      ) {
        setIsPinWrong(true);
      }

      console.log(ex, (ex as any).message);
    }
  };

  const getServiceLocationsOptions = async () => {
    try {
      const response = await request({
        url: "/serviceLocations/getServiceLocations",
        method: "POST",
        data: {
          pin: localStorage.getItem("pin"),
        },
      });
      return response;
    } catch (ex) {
      if (
        ((ex as AxiosError).response?.data as { message: string })?.message ===
        "WrongPin"
      ) {
        setIsPinWrong(true);
      }

      console.log(ex, (ex as any).message);
    }
  };

  useEffect(() => {
    getTerminalOptions().then((response) => {
      // check response is not null and is an array
      if (response && Array.isArray(response)) {
        setTerminalOptions(response);
      }

      // get url parmas and set terminalId
      const urlParams = new URLSearchParams(window.location.search);
      const terminalId = urlParams.get("terminalId") || "";
      if (terminalId) {
        setSelectedTerminal(terminalId);
        setGetTerminalId(terminalId);
      }
    });
  }, []);

  useEffect(() => {
    getServiceLocationsOptions().then((serviceLocations) => {
      // id: "clq5m495800005glvt85beve1";
      // name: "spa";           -> for option Id
      // description: "Spa";    -> just for description
      // amount: 1;             -> for payment amount
      // checkIn: false;        -> for check in
      // item: "Spa";           -> show the name for the result
      // location: "Spa";       -> for show dropdown display name
      // enabled: true;         -> disable/enable the option
      if (serviceLocations && Array.isArray(serviceLocations)) {
        for (const serviceLocation of serviceLocations) {
          if (
            locations.findIndex((l) => l.id === serviceLocation.name) === -1
            && serviceLocation.enabled
          ) {
            locations.push({
              id: serviceLocation.name,
              location: serviceLocation.location,
              items: [
                {
                  name: serviceLocation.item,
                  price: serviceLocation.amount,
                },
              ],
              checkIn: serviceLocation.checkIn,
            });
          }
        }
      }

      // get url parmas and set terminalId
      const urlParams = new URLSearchParams(window.location.search);
      const terminalId = urlParams.get("terminalId") || "";
      if (terminalId) {
        setSelectedTerminal(terminalId);
        setGetTerminalId(terminalId);
      }

      const locationId = localStorage.getItem("location");
      if (locationId) {
        const location = locations.find((location) => location.id === locationId);
        setSelectedLocation(location);
      }
    });
  }, []);

  return (
    // 100vh bootstrap class
    <div
      style={{
        width: "100vw",
      }}
      className="flex-column justify-content-center align-items-center"
    >
      <div
        style={{
          backgroundColor: "#00112c",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        className="w-100 text-light d-flex flex-column justify-content-center align-items-center"
      >
        <div
          style={{
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          <img className="mb-4" src={adyenLogo} style={{ maxWidth: 150 }} />
          <h1>Demo</h1>
          <h3 style={{ color: "#08bf51" }}></h3>
        </div>
      </div>
      <div className="mt-3 register-div w-100 d-flex flex-column justify-content-center align-items-center">
        {!loading || (
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {!terminalLoading || (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>User can now tap his card</p>
          </div>
        )}
        {loading || (
          <div
            style={{
              maxWidth: "80vw",
              width: "550px",
              background: "white",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <div className="mb-4">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedLocation(
                    locations.find((l) => l.id === e.target.value)
                  );
                  console.log(
                    "locations.find((l) => l.id === e.target.value)",
                    locations.find((l) => l.id === e.target.value)
                  );
                  localStorage.setItem("location", e.target.value);
                }}
                value={selectedLocation?.id}
                defaultValue={selectedLocation?.id}
              >
                <option value="">Select a location</option>
                {locations.map((location) => {
                  return (
                    <option value={location.id} key={location.location}>
                      {location.location}
                    </option>
                  );
                })}
              </select>
            </div>

            {!getTerminalId && (
              <div className="mb-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedTerminal(e.target.value);
                    localStorage.setItem("terminalId", e.target.value);
                  }}
                  value={selectedTerminal}
                  defaultValue={selectedTerminal}
                >
                  <option value="">Select a terminal</option>
                  {terminalOptions.map((terminalOption) => {
                    return (
                      <option value={terminalOption.id} key={terminalOption.id}>
                        {terminalOption.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div
              style={{
                height: "85px",
              }}
              className="d-flex w-100"
            >
              <button
                style={{ fontSize: "1.2rem" }}
                className="btn btn-adyen w-100"
                onClick={handleConnect}
                disabled={loading || !selectedLocation || !selectedTerminal}
              >
                {selectedLocation?.checkIn
                  ? "Register"
                  : selectedLocation
                  ? selectedLocation.id === "payment"
                    ? "Pay"
                    : `Add ${
                        selectedLocation.items?.[0].name || "product"
                      } to account`
                  : "Pick a terminal"}
              </button>
            </div>

            {/* <div
              className="d-flex mt-5 justify-content-center"
              style={{ fontSize: "1.2rem" }}>
              <a href="/activity">check activity</a>
            </div> */}

            {isPinWrong && (
              <div className="mt-3 alert alert-danger">
                The pin you entered is wrong. You can't use this page.
                <button
                  className="ml-5"
                  onClick={() => {
                    localStorage.removeItem("pin");
                    window.location.reload();
                  }}
                >
                  Reset it
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
