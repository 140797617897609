import React, { useState, useEffect } from "react";
import { useAxios } from "../hooks/axios";

interface ServiceLocations {
  id: string;
  location: string;
  name: string;
  description: string;
  item: string | null;
  amount: number | null;
  checkIn: boolean;
}

const ServiceLocations: React.FC = () => {
  const [locations, setLocations] = useState([]);
  const { loading, error, request } = useAxios();
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<ServiceLocations | null>(
    null
  );
  const [form, setForm] = useState({
    id: "",
    location: "",
    name: "",
    description: "",
    item: null as string | null,
    amount: 0 as number | null,
    checkIn: false,
  });

  const openEditModal = (location: ServiceLocations) => {
    setSelectedData(location);
    setForm({
      id: location.id,
      location: location.location,
      name: location.name,
      description: location.description,
      item: location.item,
      amount: location.amount,
      checkIn: location.checkIn,
    });

    setShowModal(true);
  };

  const openCreateLocationsModal = () => {
    setSelectedData(null);
    setForm({
      id: "",
      location: "",
      name: "",
      description: "",
      item: null,
      amount: 0,
      checkIn: false,
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formateDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.toLocaleTimeString()} \n${dateObj.toLocaleDateString()}`;
  };

  const handleCreateLocations = async () => {
    try {
      const response = await request({
        url: "/serviceLocations/setupPageCreateServiceLocation",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          id: form.id,
          location: form.location,
          name: form.name,
          description: form.description,
          item: form.item,
          amount: form.amount,
          checkIn: form.checkIn,
        },
      });

      if (response) {
        handleCloseModal();
        await fetchLocationss();
      }
    } catch (error) {}
  };

  const handleEditLocations = async () => {
    try {
      const response = await request({
        url: "/serviceLocations/setupPageUpdateServiceLocation",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          id: form.id,
          location: form.location,
          name: form.name,
          description: form.description,
          item: form.item,
          amount: form.amount,
          checkIn: form.checkIn,
        },
      });

      if (response) {
        handleCloseModal();
        await fetchLocationss();
      }
    } catch (error) {}
  };

  const handleDeleteLocations = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return false;
    }
    try {
      const response = await request({
        url: "/serviceLocations/setupPageDeleteServiceLocation",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          id,
        },
      });

      if (response) {
        await fetchLocationss();
      }
    } catch (error) {}
  };

  const handleShowLocations = (id: string, enabled: boolean) => {
    return (
      <div className="form-check form-switch">
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          Show/Hidden
        </label>
        <input
          className="form-check-input form-success"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          checked={enabled}
          onChange={() => {
            handleToggleShowLocations(id, !enabled);
          }}
        />
      </div>
    );
  };

  const handleToggleShowLocations = async (
    id: string,
    enabled: boolean
  ) => {
    if (window.confirm("Are you sure you want to change this?")) {
      const response = await request({
        url: "/serviceLocations/setupPageSetShowHiddenLocations",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
          id,
          enabled,
        },
      });

      if (response) {
        await fetchLocationss();
      }
    } else {
      return false;
    }
  };

  // Fetch locations data from backend API
  const fetchLocationss = async () => {
    try {
      const response = request({
        url: "/serviceLocations/setupPageGetAllServiceLocations",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
        },
      });

      const data = await response;
      setLocations(data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    fetchLocationss();
  }, []);

  return (
    <div>
      <button className="btn btn-adyen mb-3" onClick={openCreateLocationsModal}>
        Add Service Location
      </button>
      <table className="overview">
        <thead>
          <th>#</th>
          <th className="hide-on-mobile">DisplayName</th>
          <th>Details</th>
          <th>Actions</th>
          <th className="hide-on-mobile">Updated at</th>
        </thead>
        <tbody>
          {locations.map((location: any, index: number) => (
            <tr key={location.id}>
              <td>{index + 1}</td>
              <td className="hide-on-mobile">{location.location}</td>
              <td>
                <pre>
                  DisplayName: {location.location} {"\n"}
                  Name:{location.name} {"\n"}
                  Description: {location.description} {"\n"}
                  Item: {location.item} {"\n"}
                  Amount: {location.amount} {"\n"}
                  CheckIn: {location.checkIn ? "Yes" : "No"}
                </pre>
              </td>

              <td>
                {handleShowLocations(location.id, location.enabled)}
                <button
                  className="btn btn-primary m-2"
                  onClick={() => openEditModal(location)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    handleDeleteLocations(location.id);
                  }}
                >
                  Delete
                </button>
              </td>
              <td className="hide-on-mobile">
                <pre>{formateDate(location.updatedAt)}</pre>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div className="modal" style={{ display: "block" }} id="locationModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedData ? "Edit Locations" : "Create Locations"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  {selectedData && (
                    <div style={{ display: "none" }} className="mb-3">
                      <label htmlFor="terminalId" className="form-label">
                        Locations ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="terminalId"
                        value={selectedData.id}
                        disabled
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <label htmlFor="displayName" className="form-label">
                      DisplayName
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="displayName"
                      value={form.location}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          location: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="terminalName" className="form-label">
                      Locations name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="terminalName"
                      value={form.name}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="locationItem" className="form-label">
                      Locations item
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="locationItem"
                      value={form.item || ""}
                      disabled={form.checkIn ? true : false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          item: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="locationAmount" className="form-label">
                      Locations amount
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="locationAmount"
                      value={ form.amount || "0"  }
                      disabled={form.checkIn ? true : false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          amount: e.target.value ? Number(e.target.value) : 0,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="terminalIsTest"
                      checked={form?.checkIn}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          checkIn: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="terminalIsTest"
                    >
                      Is CheckIn
                    </label>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={
                    selectedData
                      ? () => handleEditLocations()
                      : () => handleCreateLocations()
                  }
                >
                  {selectedData ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceLocations;
