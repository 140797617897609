// create function component for onBoard page
import { useState, useEffect, useLayoutEffect } from "react";
import { useAxios } from "../hooks/axios";
import useSWR from "swr";
import { API } from "../config";

const fetcher = (url: string) => {
  const pin = localStorage.getItem("superPin") || "";
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-pin": pin,
    },
  }).then((res) => res.json());
};

export const UserOverviewPage = () => {
  const { loading: isDeleting, request: deleteRequest } = useAxios();

  const [isPinWrong, setIsPinWrong] = useState(false);

  const { data, error, isLoading, mutate } = useSWR(
    `${API.HOST}/api/overview`,
    fetcher
  );

  useLayoutEffect(() => {
    if (!localStorage.getItem("superPin")) {
      const pin = window.prompt("Please enter your admin pin");

      if (pin && pin !== "null") {
        localStorage.setItem("superPin", pin);
      }
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data && error) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      <h3>User Overview</h3>

      <h5>User count: {data?.length}</h5>

      <table className="overview">
        <thead>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          <th>Alias</th>
          <th>Transaction count</th>
          <th>Transaction events</th>
          <th>Activity</th>
          <th>Registered on</th>
        </thead>
        <tbody>
          {data?.map((user: any, ix: number) => {
            const hasCard = user.Card.length > 0;

            if (!hasCard) {
              return (
                <tr>
                  <td colSpan={"100%" as any}>
                    {user.email}: No card connected
                  </td>
                </tr>
              );
            }

            return (
              <tr>
                <td>{ix + 1}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.Card[0].cardAlias}</td>
                <td>{user.Card[0].ActivityLog.length}</td>
                <td>
                  {user.Card[0].ActivityLog.map((ac: any) => {
                    return new Date(ac?.createdAt || "").toLocaleTimeString(
                      "nl-NL",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    );
                  }).join(", ")}
                </td>
                <td>
                  <a
                    target="_blank"
                    href={window.location.href.replace(
                      "user-overview",
                      `activity?id=${user.id}`
                    )}
                    rel="noreferrer">
                    Here
                  </a>
                </td>
                <td>
                  {new Date(user?.createdAt || "").toLocaleTimeString("nl-NL", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </td>
                <td>
                  <button
                    className="btn btn-adyen"
                    disabled={isDeleting}
                    onClick={() => {
                      deleteRequest({
                        url: `/hospitality/deleteUser/${user.id}`,
                        method: "DELETE",
                      }).then(() => {
                        mutate();
                      });
                    }}>
                    Delete user data
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
