// create function component for onBoard page
import React,{ useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useAxios } from "../hooks/axios";

export const ActivityPhonePage = () => {
  const { loading,error,request } = useAxios();
  const [activityLog,setActivityLog] = useState(
    [] as {
      id: string;
      message: string;
      timestamp: Date;
    }[]
  );
  const [name,setName] = useState("User");
  const [phone,setPhone] = useState("");

  const init = async () => {
    setName("User");
    setPhone("");
    setActivityLog([]);
  };

  const getActivityLogs = async ({ phone }: { phone: string }) => {
    const data = await request({
      url: "/getActivityLogs",
      method: "POST",
      data: {
        phone,
      },
    });

    if (data && data?.ActivityLog?.length > 0) {
      setName(data.name);
      setActivityLog(
        data.ActivityLog.map(
          (data: { id: string; message: string; createdAt: Date }) => {
            return {
              id: data.id,
              message: data.message,
              timestamp: new Date(data?.createdAt),
            };
          }
        )
      );
    }

    if (!data) {
      init();
    }

    return data;
  };

  const hanldInquiryActivity = async () => {
    getActivityLogs({
      phone,
    });
  };

  return (
    <div>
      <div
        style={{
          minHeight: "50vh",
          backgroundColor: "#00112c",
        }}
        className="w-100 text-light d-flex flex-column justify-content-center align-items-center"
      >
        <div
          style={{
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          <img
            className="mb-4"
            src="https://loyyo.nl/images/hitec-logo.png?v2"
            style={{ maxWidth: 60 }}
          />
          <h1>Hi {name},</h1>
          <h3 style={{ color: "#08bf51" }}>This is your activity.</h3>
          <h4
            style={{
              background: "#08bf51",
              display: "inline-block",
              padding: "5px 20px",
              borderRadius: "20px",
              lineHeight: "19px",
            }}
          >
            {activityLog.length * 100} points
          </h4>
        </div>
      </div>
      <div className="mt-5 w-100 d-flex flex-column justify-content-center align-items-center">
        <div className="h-20">
          <div className="d-flex flex-column w-100">
            <div className="input-group mb-3">
              <span className="input-group-text" id="inputGroup-phone">
                Phone
              </span>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter phone number"
                className="form-control"
                aria-label="input phone number"
                aria-describedby="inputGroup-phone"
              />
            </div>
            <button
              className="btn btn-adyen"
              onClick={hanldInquiryActivity}
              disabled={loading}
            >
              Inquiry
            </button>
          </div>
        </div>

        {!loading || (
          <div className="h-80 d-flex justify-content-center align-items-center">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {/* {loading || ()} */}
        <div className="activity-table">
          {activityLog.map((o) => {
            return (
              <div className="row" key={o.id}>
                <div>{o.message}</div>
                <div>
                  {o.timestamp.toLocaleTimeString("nl-NL", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
                <div style={{ color: "#08bf51" }}>100pt</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
